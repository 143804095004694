import React from 'react';
import PageLayout from '../Layout/Page';
import { useStaticQuery, graphql } from 'gatsby';
import GraduateItem from './Item';
import { resolveGraduateProps } from './utils';
import usePreviewData from '../../utils/usePreviewData';

const GraduatePage = ({
  pageContext: {
    data: { uid },
  },
}) => {
  const data = useStaticQuery(graphql`
    {
      allPrismicGraduateMember {
        nodes {
          id
          uid
          data {
            graduate_name
            graduate_bbsh_graduation_list {
              bbsh_graduation
            }
            graduate_bio {
              html
            }
            graduate_city
            graduate_state
            graduate_country
            graduate_portrait {
              fluid(maxWidth: 128) {
                ...GatsbyPrismicImageFluid
              }
            }
            graduate_is_active
            graduate_is_featured
            graduate_is_integration_practitioner
            graduate_email_address
            graduate_phone_list {
              phone_number
            }
            graduate_website {
              url
            }
            graduate_street_address
            graduate_postal_code
            graduate_session_phone
            graduate_skype
            graduate_session_skype
            graduate_session_facetime
            graduate_session_zoom
            graduate_session_other
            graduate_language_list {
              graduate_language
            }
          }
        }
      }
    }
  `);
  const list = data.allPrismicGraduateMember.nodes;
  const graduate = list.find(graduate => graduate.uid === uid);
  const liveData = usePreviewData({ prismicGraduateMember: graduate });
  const itemProps = resolveGraduateProps(liveData.prismicGraduateMember);
  return (
    <PageLayout title={graduate.data.graduate_name}>
      <GraduateItem single {...itemProps} />
    </PageLayout>
  );
};

export default GraduatePage;
